<template>
  <section>
    <div>
      <v-dialog v-model="dialogTutorial" persistent max-width="1200px">
        <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
          <v-card-text style="background-color: #f1f4f9">
            <v-row>
              <v-col cols="12" md="3" class="no-padding ml-n2">
                <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                  <v-card-title>
                    <v-img
                      :src="require('@/assets/images/logos/anavel_logo.png')"
                      max-height="150px"
                      max-width="120px"
                      alt="logo"
                      contain
                    ></v-img>
                  </v-card-title>
                  <v-card-title class="text-center justify-center muller-capitalized"
                    >Mes Services Assurance</v-card-title
                  >
                  <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL</v-card-subtitle>
                  <v-card-text class="no-padding">
                    <v-img :src="require('@/assets/images/logos/layer.png')" alt="logo" width="300px" class=""> </v-img>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="9" class="no-padding d-flex">
                <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                  <v-card-title class="muller-capitalized EoleBlue--text">
                    Nous vous proposons maintenant une pré-configuration rapide de votre solution
                  </v-card-title>
                  <v-card-subtitle class="mt-5"> Configuration de l'administrateur général </v-card-subtitle>
                  <v-card-text>
                    <v-stepper v-model="stepperAccountTutorial" flat>
                      <v-stepper-items>
                        <el-steps :active="stepperTutorial" finish-status="success" simple>
                          <el-step title="Votre compte"></el-step>
                          <el-step title="Invitations"></el-step>
                          <el-step title="Étape 3"></el-step>
                        </el-steps>

                        <!-- <v-stepper-header>
                        <v-stepper-step :key="`1-step`" :complete="stepperAccount > 1" :step="1" editable>
                          Informations
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step :key="`2-step`" :complete="stepperAccount > 2" :step="2" editable>
                          Informations familliale
                        </v-stepper-step>

                        <v-divider></v-divider>
                        <v-stepper-step :key="`3-step`" :complete="stepperAccount > 3" :step="3" editable>
                          Informations contractuelle
                        </v-stepper-step>

                        <v-divider></v-divider>
                        <v-stepper-step :key="`4-step`" :complete="stepperAccount > 4" :step="4" editable>
                          Complément d'information
                        </v-stepper-step>
                      </v-stepper-header> -->
                        <v-stepper-content step="0" class="no-padding">
                          <v-card-title>
                            <span class="text-h5">Informations professionnelles</span>
                          </v-card-title>
                          <v-card-text class="mt-5">
                            <v-form ref="form" v-model="validityFormPassword" lazy-validation>
                              <v-row>
                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    id="new-account-lastname"
                                    dense
                                    prepend-inner-icon="mdi-account"
                                    outlined
                                    :rules="basicRules"
                                    :error-messages="lastnameErrorMessage"
                                    v-model="editedItem.lastname"
                                    label="Nom utilisateur"
                                  >
                                  </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    id="new-account-firstname"
                                    dense
                                    prepend-inner-icon="mdi-account"
                                    outlined
                                    :rules="basicRules"
                                    :error-messages="firstnameErrorMessage"
                                    v-model="editedItem.firstname"
                                    label="Prénom utilisateur"
                                  >
                                  </v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" sm="6" md="6">
                                  <v-select
                                    id="new-account-status"
                                    dense
                                    prepend-inner-icon="mdi-cogs"
                                    outlined
                                    value="editedItem.statut"
                                    v-model="editedItem.statut"
                                    :items="states"
                                    label="Type d'utilisateur"
                                  ></v-select>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                  <v-select
                                    outlined
                                    dense
                                    label="Lieu de travail principal"
                                    :items="agency.etablishment"
                                    item-value="id"
                                    item-text="city"
                                    v-model="searchEtablishment"
                                  ></v-select>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    id="new-account-street"
                                    dense
                                    prepend-inner-icon="mdi-account"
                                    outlined
                                    v-model="editedItem.mainWorkplaceStreet"
                                    label="Adresse"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                  <v-autocomplete
                                    id="new-account-city"
                                    messages="Entrez un code postal"
                                    :search-input.sync="searchCityProfessionnal"
                                    :items="cityItemsProfessionnal"
                                    outlined
                                    v-model="editedItem.mainWorkplaceCity"
                                    :value="editedItem.mainWorkplaceZip"
                                    label="Ville"
                                    clearable
                                    hide-details
                                    hide-selected
                                    dense
                                    no-filter
                                  >
                                    <template v-slot:no-data>
                                      <v-list-item>
                                        <v-list-item-title> Entrez un code postal </v-list-item-title>
                                      </v-list-item>
                                    </template>

                                    <template v-slot:item="{ item }">
                                      <v-list-item-content>
                                        <v-list-item-title>{{ item }}</v-list-item-title>
                                      </v-list-item-content>
                                    </template></v-autocomplete
                                  >
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    id="new-account-email"
                                    dense
                                    prepend-inner-icon="mdi-gmail"
                                    outlined
                                    :rules="emailRules"
                                    :error-messages="emailErrorMessage"
                                    v-model="editedItem.email"
                                    label="Mail professionnel"
                                  >
                                  </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    id="new-account-phone"
                                    dense
                                    prepend-inner-icon="mdi-phone"
                                    outlined
                                    :rules="phoneNumberRules"
                                    :error-messages="phoneNumberErrorMessage"
                                    v-model="editedItem.phoneNumberProfessional"
                                    label="N° professionnel"
                                  >
                                  </v-text-field>
                                </v-col>
                              </v-row>
                            </v-form>
                          </v-card-text>
                        </v-stepper-content>

                        <v-stepper-content step="1" class="no-padding">
                          <v-card-title>
                            <span class="text-h5">Informations personnelles</span>
                          </v-card-title>

                          <v-card-text>
                            <v-form ref="form">
                              <v-row>
                                <v-col cols="12" md="6">
                                  <v-text-field
                                    id="new-account-street"
                                    dense
                                    prepend-inner-icon="mdi-account"
                                    outlined
                                    v-model="editedItem.street"
                                    label="Adresse personnelle"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                  <v-autocomplete
                                    id="new-account-city"
                                    messages="Entrez un code postal"
                                    :search-input.sync="searchCity"
                                    :items="cityItems"
                                    outlined
                                    v-model="editedItem.city"
                                    :value="editedItem.zip"
                                    label="Ville"
                                    clearable
                                    hide-details
                                    hide-selected
                                    dense
                                    no-filter
                                  >
                                    <template v-slot:no-data>
                                      <v-list-item>
                                        <v-list-item-title> Entrez un code postal </v-list-item-title>
                                      </v-list-item>
                                    </template>

                                    <template v-slot:item="{ item }">
                                      <v-list-item-content>
                                        <v-list-item-title>{{ item }}</v-list-item-title>
                                      </v-list-item-content>
                                    </template></v-autocomplete
                                  >
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    id="new-account-email"
                                    dense
                                    prepend-inner-icon="mdi-gmail"
                                    outlined
                                    :rules="emailRules"
                                    :error-messages="emailErrorMessage"
                                    v-model="editedItem.personnalEmail"
                                    label="Mail personnel"
                                  >
                                  </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    id="new-account-phone"
                                    dense
                                    prepend-inner-icon="mdi-phone"
                                    outlined
                                    :rules="phoneNumberRules"
                                    :error-messages="phoneNumberErrorMessage"
                                    v-model="editedItem.personnalPhoneNumber"
                                    label="N° personnel"
                                  >
                                  </v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" sm="6" md="6">
                                  <v-select
                                    dense
                                    prepend-inner-icon="mdi-cogs"
                                    outlined
                                    v-model="editedItem.idMaritalStatus"
                                    :items="[
                                      {
                                        id: 1,
                                        designation: 'Célibataire',
                                      },

                                      {
                                        id: 2,
                                        designation: 'En concubinage',
                                      },
                                      {
                                        id: 3,
                                        designation: 'Marié(e)',
                                      },
                                      {
                                        id: 4,
                                        designation: 'Pacsé(e)',
                                      },
                                      {
                                        id: 5,
                                        designation: 'Divorcé(e)',
                                      },
                                      {
                                        id: 6,
                                        designation: 'Veuf(ve)',
                                      },
                                    ]"
                                    item-text="designation"
                                    item-value="id"
                                    label="Situation familiale"
                                  ></v-select>
                                </v-col>
                                <v-col cols="12" md="6" class="mt-n3">
                                  <v-switch
                                    inset
                                    v-model="editedItem.hasChildren"
                                    label="Avez-vous des enfants ?"
                                  ></v-switch>
                                </v-col>
                              </v-row>
                            </v-form>
                          </v-card-text>

                          <v-card-text
                            class="no-padding mb-10"
                            v-if="
                              editedItem.hasChildren ||
                              editedItem.idMaritalStatus === 3 ||
                              editedItem.idMaritalStatus === 2 ||
                              editedItem.idMaritalStatus === 4
                            "
                          >
                            <v-form>
                              <v-simple-table dense class="no-padding">
                                <template v-slot:default>
                                  <thead>
                                    <tr>
                                      <th class="text-center">Nature du lien</th>
                                      <th class="text-center">Nom de famille</th>
                                      <th class="text-center">Prénom</th>
                                      <th class="text-center">Contact d'urgence</th>
                                      <th class="text-center">Téléphone</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="(familyMember, index) in editedItem.familyMembers" v-bind:key="index">
                                      <td class="text-left">
                                        <v-select
                                          class="mt-6"
                                          dense
                                          :id="`input-family-${index}`"
                                          outlined
                                          v-model="familyMember.id_account_family_link"
                                          :items="familyOptions"
                                          item-text="designation"
                                          item-value="id"
                                        ></v-select>
                                      </td>
                                      <td class="text-left">
                                        <v-text-field
                                          ref="newTextField"
                                          outlined
                                          class="mt-6"
                                          dense
                                          v-model="familyMember.lastname"
                                          single-line
                                        ></v-text-field>
                                      </td>

                                      <td class="text-left">
                                        <v-text-field
                                          ref="newTextField"
                                          outlined
                                          class="mt-6"
                                          dense
                                          v-model="familyMember.firstname"
                                          single-line
                                        ></v-text-field>
                                      </td>

                                      <td class="text-center justify-center">
                                        <v-switch class="ml-7" inset v-model="familyMember.isEmergencyContact">
                                        </v-switch>
                                      </td>
                                      <td class="text-left">
                                        <v-text-field
                                          v-if="familyMember.isEmergencyContact"
                                          ref="newTextField"
                                          outlined
                                          class="mt-6"
                                          dense
                                          v-model="familyMember.phoneNumber"
                                          single-line
                                        ></v-text-field>
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                              <v-row>
                                <v-col cols="12" md="4">
                                  <v-btn
                                    style="text-transform: none"
                                    elevation="0"
                                    @click="addLine(editedItem.familyMembers)"
                                  >
                                    <v-icon left>mdi-plus-circle</v-icon> Ajouter un membre à la famille
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-form>
                          </v-card-text>
                        </v-stepper-content>

                        <v-stepper-content step="2" class="no-padding">
                          <v-card-title>
                            <span class="text-h5">Relation contractuelle avec l'entreprise</span>
                          </v-card-title>

                          <v-card-text>
                            <v-container>
                              <v-form ref="form" v-model="validityFormPassword" lazy-validation>
                                <v-row>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                      id="input-date-entry"
                                      dense
                                      prepend-inner-icon="mdi-phone"
                                      outlined
                                      type="date"
                                      v-model="editedItem.entryDate"
                                      label="Date d'entrée dans l'entreprise"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="6"> </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-select
                                      id="select-type-contract"
                                      dense
                                      prepend-inner-icon="mdi-cogs"
                                      outlined
                                      v-model="editedItem.idTypeContract"
                                      :items="typeContracts"
                                      label="Type de relation"
                                      item-text="designation"
                                      item-value="id"
                                    >
                                      <template v-slot:item="{ item }">
                                        <v-list-item-content :id="`item-type-contract-${item.id}`">
                                          <v-list-item-title>{{ item.designation }}</v-list-item-title>
                                        </v-list-item-content>
                                      </template>
                                    </v-select>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-select
                                      id="select-type-employment"
                                      dense
                                      prepend-inner-icon="mdi-cogs"
                                      outlined
                                      :items="typeEmployments"
                                      item-text="designation"
                                      item-value="id"
                                      label="Type d'emploi"
                                      v-model="editedItem.idTypeEmployment"
                                      ><template v-slot:item="{ item }">
                                        <v-list-item-content :id="`item-type-employment-${item.id}`">
                                          <v-list-item-title>{{ item.designation }}</v-list-item-title>
                                        </v-list-item-content>
                                      </template>
                                    </v-select>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-select
                                      id="select-type-job"
                                      dense
                                      prepend-inner-icon="mdi-cogs"
                                      outlined
                                      v-model="editedItem.idTypeJob"
                                      :items="typeJobs"
                                      item-text="designation"
                                      item-value="id"
                                      label="Poste"
                                      ><template v-slot:item="{ item }">
                                        <v-list-item-content :id="`item-type-job-${item.id}`">
                                          <v-list-item-title>{{ item.designation }}</v-list-item-title>
                                        </v-list-item-content>
                                      </template>
                                    </v-select>
                                  </v-col>

                                  <v-col cols="12" sm="6" md="6">
                                    <v-select
                                      id="select-type-convention"
                                      dense
                                      prepend-inner-icon="mdi-cogs"
                                      outlined
                                      :items="[
                                        {
                                          id: 1,
                                          designation: `Convention collective des agents généraux d'assurance`,
                                        },
                                        {
                                          id: 2,
                                          designation: `Convention collective du courtage d'assurance`,
                                        },
                                      ]"
                                      item-text="designation"
                                      item-value="id"
                                      label="Convention"
                                      v-model="editedItem.idCollectiveAgreement"
                                    >
                                      <template v-slot:item="{ item }">
                                        <v-list-item-content :id="`item-type-qualification-${item.id}`">
                                          <v-list-item-title>{{ item.designation }}</v-list-item-title>
                                        </v-list-item-content>
                                      </template></v-select
                                    >
                                  </v-col>
                                </v-row>
                                <v-row v-if="editedItem.idConvention === 1">
                                  <v-col cols="12" sm="6" md="6">
                                    <v-select
                                      id="select-type-qualification"
                                      dense
                                      prepend-inner-icon="mdi-cogs"
                                      outlined
                                      :items="typeQualifications"
                                      item-text="designation"
                                      item-value="id"
                                      label="Classification du poste"
                                      v-model="editedItem.idTypeQualification"
                                    >
                                      <template v-slot:item="{ item }">
                                        <v-list-item-content :id="`item-type-qualification-${item.id}`">
                                          <v-list-item-title>{{ item.designation }}</v-list-item-title>
                                        </v-list-item-content>
                                      </template></v-select
                                    >
                                  </v-col>
                                </v-row>
                              </v-form>
                            </v-container>
                          </v-card-text>
                        </v-stepper-content>

                        <v-stepper-content step="3" class="no-padding">
                          <v-card-title>
                            <span class="text-h5">Inviter vos collaborateurs</span>
                          </v-card-title>
                          <v-card-text>
                            <v-combobox
                              :disabled="emailLoading"
                              label="Entrez vos adresses email"
                              v-model="emailInput"
                              placeholder="Email1;Email2;Email3;..."
                              chips
                              multiple
                              @keyup.enter="testEmail"
                              color="EoleBlue"
                            >
                            </v-combobox>
                          </v-card-text>
                        </v-stepper-content>
                      </v-stepper-items>
                    </v-stepper>
                    <v-card-actions>
                      <v-btn id="btn-last" v-if="stepperAccountTutorial > 0" text @click="stepperAccountTutorial--"
                        >retour</v-btn
                      >
                      <v-spacer></v-spacer>

                      <v-btn id="btn-next" v-if="stepperAccountTutorial < 2" text @click="stepperAccountTutorial++"
                        >suivant</v-btn
                      >
                      <!-- <v-btn
                        id="btn-validate"
                        v-if="stepperAccountTutorial === 2"
                        class="white--text"
                        color="EoleGreen"
                        @click="putAccountById"
                      >
                        Valider
                      </v-btn> -->
                      <v-btn
                        id="btn-validate"
                        v-if="stepperAccountTutorial === 2"
                        class="white--text"
                        color="EoleGreen"
                        @click="stepperAccountTutorial++"
                      >
                        Valider
                      </v-btn>
                      <v-btn
                        :disabled="emailLoading"
                        :loading="emailLoading"
                        id="btn-invitation"
                        v-if="stepperAccountTutorial === 3"
                        class="white--text"
                        color="EoleGreen"
                        @click="sendInvitationMail"
                      >
                        Inviter mes collaborateurs
                      </v-btn>
                    </v-card-actions>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-carousel v-if="isGuest" class="carousel rounded-xl" cycle interval="10000" height="460" show-arrows-on-hover>
        <v-carousel-item v-for="(slide, i) in slides" :key="i" :src="slide.url">
          <v-row class="carousel pa-15" justify="center">
            <v-col cols="12" md="12">
              <v-img max-width="250px" :src="require(`../../../../src/assets/images/logos/anavel_logo.png`)"></v-img>
            </v-col>

            <v-col v-if="!slide.videoAvailable">
              <v-card-title class="text-h4">{{ slide.title }}</v-card-title>
              <v-card-subtitle v-if="user.grade === 'franchiseur'"
                >Bonjour <strong>{{ user.name }},</strong> <br />
                Bienvenue sur votre espace courtage « Mes Services Assurance » <br />
                Récupérer tous vos contrats ou attestations en un clin d'oeil grâce aux outils proposés
                !</v-card-subtitle
              >
              <v-card-subtitle v-else-if="user.grade === 'client'"
                >Bonjour <strong>{{ user.name }},</strong> <br />
                Bienvenue sur votre espace client « Mes Services Assurance » <br />
                Récupérer tous vos contrats ou attestations en un clin d'oeil grâce aux outils proposés
                !</v-card-subtitle
              >
              <v-card-subtitle v-else
                >Bonjour <strong>{{ user.name }},</strong> <br />
                Bienvenue sur votre espace collaborateur « Mes Services Assurance » <br />
                Générer vos documents papiers, gérer vos clients depuis votre espace grâce aux outils proposés
                !</v-card-subtitle
              >
            </v-col>
            <v-col v-else>
              <v-card-title class="text-h4 mb-5">{{ slide.title }}</v-card-title>
              <v-card-subtitle>
                <iframe
                  width="560"
                  height="300"
                  :src="`${slide.url}?controls=0`"
                  title="Anavel Assurance"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe
              ></v-card-subtitle>
            </v-col>
          </v-row>
        </v-carousel-item>
      </v-carousel>
      <v-row v-else class="mt-5">
        <v-col class="d-flex" cols="12" md="3" sm="6">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-card class="flex d-flex flex-column rounded-lg" v-bind="attrs" v-on="on" flat outlined>
                <v-card-subtitle>
                  <v-avatar color="EoleBlueLighten" class="mr-2">
                    <i
                      class="fa-regular fa-calendar-check fa-1x EoleBlue--text"
                      style="font-size: 1.5rem"
                    ></i> </v-avatar
                  >Mes rendez-vous programmés</v-card-subtitle
                >
                <div v-if="appointmentsAttributed.length > 0" class="no-padding">
                  <v-card-text class="text-center">
                    <h2 class="font-weight-bold" style="font-size: 2rem">{{ appointmentsAttributed.length }}</h2>
                  </v-card-text>
                </div>
                <v-card-text v-else class="text-center">
                  <p>Vous n'avez aucun rendez-vous pour le moment.</p>
                </v-card-text>
              </v-card>
            </template>
            <span>Les rendez-vous en attente sont affichés dans la liste ci-dessous. </span>
          </v-tooltip>
        </v-col>
        <v-col class="d-flex" cols="12" md="3" sm="6">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-card class="flex d-flex flex-column rounded-lg" v-bind="attrs" v-on="on" outlined>
                <v-card-subtitle>
                  <v-avatar color="EoleBlueLighten" class="mr-2">
                    <i class="fa-solid fa-phone-volume fa-1x EoleBlue--text" style="font-size: 1.5rem"></i> </v-avatar
                  >Mes demandes de rappel</v-card-subtitle
                >
                <v-row align="center">
                  <v-card-text v-if="contactNumber > 0" class="text-center align-center">
                    <h2 class="font-weight-bold" style="font-size: 2rem">{{ contactNumber }}</h2>
                  </v-card-text>
                  <v-card-text v-else class="text-center">
                    <p>Vous n'avez aucune demande de rappel en attente.</p>
                  </v-card-text>
                </v-row>
              </v-card>
            </template>
            <span>Les demandes de rappels qui vous sont attribuées. </span>
          </v-tooltip>
        </v-col>
        <!-- <v-col v-if="isAdmin" class="d-flex" cols="12" md="3" sm="6">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-card class="flex d-flex flex-column rounded-lg" v-bind="attrs" v-on="on" outlined>
                <v-card-subtitle
                  ><v-avatar color="EoleBlueLighten" class="mr-2">
                    <i
                      class="fa-solid fa-users-viewfinder fa-1x EoleBlue--text"
                      style="font-size: 1.3rem"
                    ></i> </v-avatar
                  >Mon portefeuille client</v-card-subtitle
                >

                <v-row align="center">
                  <v-card-text v-if="customersCount > 0" class="text-center align-center">
                    <h2 class="font-weight-bold" style="font-size: 2rem">{{ customersCount }}</h2>
                  </v-card-text>
                  <v-card-text v-else class="text-center">
                    <p>Il n'y a aucun client de repertorié actuellement.</p>
                  </v-card-text>
                </v-row>
              </v-card>
            </template>
            <span>Affiche le nombre de client(s) répertorié en base pour votre agence {{ user.agency }}. </span>
          </v-tooltip>
        </v-col> -->
        <!-- <v-col class="d-flex" cols="12" md="3" sm="6">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-card class="flex d-flex flex-column rounded-lg" v-bind="attrs" v-on="on" outlined>
                <v-card-subtitle
                  ><v-avatar color="EoleBlueLighten" class="mr-2">
                    <i class="fa-solid fa-warning fa-1x EoleBlue--text" style="font-size: 1.5rem"></i>
                  </v-avatar>
                  Les réclamations en cours</v-card-subtitle
                >

                <v-row align="center">
                  <v-card-text v-if="complaintsCount > 0" class="text-center align-center">
                    <h2 class="font-weight-bold" style="font-size: 2rem">{{ complaintsCount }}</h2>
                  </v-card-text>
                  <v-card-text v-else class="text-center">
                    <p>Il n'y a aucune réclamation en cours pour le moment.</p>
                  </v-card-text>
                </v-row>
              </v-card>
            </template>
            <span>Le nombre de réclamations de l'agence. </span>
          </v-tooltip>
        </v-col> -->
        <!-- <v-col class="d-flex" cols="12" md="3" sm="6">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-card class="flex d-flex flex-column rounded-lg" v-bind="attrs" v-on="on" outlined>
                <v-card-subtitle
                  ><v-avatar color="EoleBlueLighten" class="mr-2">
                    <i class="fa-solid fa-chart-simple fa-1x EoleBlue--text" style="font-size: 1.5rem"></i>
                  </v-avatar>
                  Mes dernières demandes</v-card-subtitle
                >

                <v-row align="center">
                  <v-card-text class="text-center align-center">
                    <apexchart type="bar" :options="chartOptions" :series="series"></apexchart>
                  </v-card-text>
                </v-row>
              </v-card>
            </template>
            <span>Le nombre de réclamations de l'agence. </span>
          </v-tooltip>
        </v-col> -->
        <v-col class="d-flex" cols="12" md="3" sm="6">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-card class="flex d-flex flex-column rounded-lg" v-bind="attrs" v-on="on" outlined>
                <v-card-subtitle
                  ><v-avatar color="EoleBlueLighten" class="mr-2">
                    <i class="fa-solid fa-umbrella-beach fa-1x EoleBlue--text" style="font-size: 1.5rem"></i>
                  </v-avatar>
                  Mes prochains congés</v-card-subtitle
                >

                <v-row align="center">
                  <!-- <v-card-text class="text-center" >
                    <p class="EoleError--text">Aucun congés posé pour le moment</p>
                  </v-card-text> -->
                  <v-card-text class="text-center" v-if="account.next_holiday != null">
                    <h2 class="font-weight-bold" style="font-size: 1.1rem">
                      Du {{ account.next_holiday[0].start }} au {{ account.next_holiday[0].end }}
                    </h2>
                  </v-card-text>
                </v-row>
              </v-card>
            </template>
            <span>Vos prochains congés.</span>
          </v-tooltip>
        </v-col>
        <!-- <v-col class="d-flex" cols="12" md="3">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-card class="flex d-flex flex-column" v-bind="attrs" v-on="on" outlined>
                <v-card-subtitle
                  ><v-avatar color="EoleBlueLighten" class="mr-2">
                    <i class="fa-solid fa-star fa-1x EoleBlue--text" style="font-size: 1.5rem"></i>
                  </v-avatar>
                  Mes clients VIP</v-card-subtitle
                >

                <v-row align="center">
                  <v-card-text v-if="customersCountVIP > 0" class="text-center align-center">
                    <h2 class="font-weight-bold" style="font-size: 2rem">{{ customersCountVIP }}</h2>
                  </v-card-text>
                  <v-card-text v-else class="text-center">
                    <p>Vous n'avez aucun client VIP pour le moment.</p>
                  </v-card-text>
                </v-row>
              </v-card>
            </template>
            <span>Affiche le nombre de client(s) considéré comme VIP. </span>
          </v-tooltip>
        </v-col> -->
      </v-row>

      <custome-loader v-if="waitTimeoutContact"></custome-loader>

      <v-row no-gutters class="mt-5">
        <v-col md="5">
          <h2 v-if="verifyLength(servicesItems)" class="my-4 EoleYellow--text title-text">MES SERVICES ASSURANCES</h2>
          <v-row :class="$vuetify.breakpoint.xs ? 'justify-center' : ''">
            <template v-if="$vuetify.breakpoint.md">
              <v-col
                v-if="
                  (service.admin && isAdmin) || (service.collaborator && isCollaborator) || (service.guest && isGuest)
                "
                class="text-center d-flex"
                cols="12"
                md="4"
                v-for="service in servicesItems"
                :key="service.id"
              >
                <v-tooltip v-model="showDescriptionService[service.id]" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }">
                      <v-card
                        :disabled="service.disable === true"
                        @mouseover="hover = true"
                        @mouseout="hover = false"
                        outlined
                        elevation="3"
                        :href="service.disable === true ? '#' : service.path"
                        :target="service.otherSite === true ? '_blank' : ''"
                        max-width="125px"
                        max-height="125px"
                        :class="[
                          service.disable === true
                            ? 'disableCursor rounded-xl justify-center'
                            : 'rounded-xl  justify-center',
                          hover ? 'hover-card-dashboard' : '',
                        ]"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-card-text class="text-h7">
                          <i
                            :class="[
                              service.class,
                              'EoleBlue--text',
                              hover ? [service.animation, 'EoleYellow--text'] : '',
                            ]"
                          ></i>
                        </v-card-text>
                        <v-card-text class="text-caption EoleBlue--text card-title">{{ service.content }}</v-card-text>
                      </v-card>
                    </v-hover>
                  </template>

                  <span>{{ service.content }}</span>
                </v-tooltip>
              </v-col>
            </template>
            <template v-else>
              <v-col
                v-if="
                  (service.admin && isAdmin) || (service.collaborator && isCollaborator) || (service.guest && isGuest)
                "
                class="text-center d-flex"
                cols="12"
                md="3"
                sm="6"
                v-for="service in servicesItems"
                :key="service.id"
              >
                <v-tooltip v-model="showDescriptionService[service.id]" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }">
                      <v-card
                        :disabled="service.disable === true"
                        @mouseover="hover = true"
                        @mouseout="hover = false"
                        outlined
                        elevation="3"
                        :href="service.disable === true ? '#' : service.path"
                        :target="service.otherSite === true ? '_blank' : ''"
                        max-width="125px"
                        max-height="125px"
                        :class="[
                          service.disable === true
                            ? 'disableCursor rounded-xl flex d-flex flex-column justify-center'
                            : 'rounded-xl flex d-flex flex-column justify-center',
                          hover ? 'hover-card-dashboard' : '',
                        ]"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-card-text class="text-h7">
                          <i
                            :class="[
                              service.class,
                              'EoleBlue--text',
                              hover ? [service.animation, 'EoleYellow--text'] : '',
                            ]"
                          ></i>
                        </v-card-text>
                        <v-card-text class="text-caption EoleBlue--text card-title">{{ service.content }}</v-card-text>
                      </v-card>
                    </v-hover>
                  </template>

                  <span>{{ service.content }}</span>
                </v-tooltip>
              </v-col>
            </template>
          </v-row>
        </v-col>
        <v-col cols="12" sm="4" md="1"> </v-col>
        <v-col md="5">
          <h2 v-if="verifyLength(toolsItems)" class="my-4 EoleYellow--text title-text">MES OUTILS CABINET</h2>

          <v-row :class="$vuetify.breakpoint.xs ? 'justify-center' : ''">
            <template v-if="$vuetify.breakpoint.xs">
              <v-col
                v-if="(tool.admin && isAdmin) || (tool.collaborator && isCollaborator) || (tool.guest && isGuest)"
                class="text-center d-flex"
                cols="5"
                v-for="tool in toolsItems"
                :key="tool.id"
              >
                <v-tooltip v-model="showDescriptionTool[tool.id]" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }">
                      <v-card
                        :disabled="tool.disable === true"
                        @mouseover="hover = true"
                        @mouseout="hover = false"
                        outlined
                        elevation="3"
                        :href="tool.disable === true ? '#' : tool.path"
                        :target="tool.otherSite === true ? '_blank' : ''"
                        max-width="125px"
                        max-height="125px"
                        :class="[
                          tool.disable === true
                            ? 'disableCursor rounded-xl flex d-flex flex-column justify-center'
                            : 'rounded-xl flex d-flex flex-column justify-center',
                          hover ? 'hover-card-dashboard' : '',
                        ]"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-card-text class="text-h7">
                          <i
                            :class="[tool.class, 'EoleBlue--text', hover ? [tool.animation, 'EoleYellow--text'] : '']"
                          ></i>
                          <v-badge
                            v-if="tool.id === 14 && contactNumber > 0"
                            color="EoleError"
                            :content="contactNumber"
                            offset-x="10"
                            offset-y="20"
                          ></v-badge>
                          <v-badge
                            v-if="tool.id === 19 && appointmentNumber > 0"
                            color="EoleError"
                            :content="appointmentNumber"
                            offset-x="10"
                            offset-y="20"
                          ></v-badge>
                        </v-card-text>
                        <v-card-text class="text-caption EoleBlue--text card-title">{{ tool.content }}</v-card-text>
                      </v-card>
                    </v-hover>
                  </template>

                  <span>{{ tool.content }}</span>
                </v-tooltip>
              </v-col>
            </template>
            <template v-else-if="$vuetify.breakpoint.md">
              <v-col
                v-if="(tool.admin && isAdmin) || (tool.collaborator && isCollaborator) || (tool.guest && isGuest)"
                class="text-center d-flex"
                cols="12"
                md="4"
                v-for="tool in toolsItems"
                :key="tool.id"
              >
                <v-tooltip v-model="showDescriptionTool[tool.id]" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }">
                      <v-card
                        :disabled="tool.disable === true"
                        @mouseover="hover = true"
                        @mouseout="hover = false"
                        outlined
                        elevation="3"
                        :href="tool.disable === true ? '#' : tool.path"
                        :target="tool.otherSite === true ? '_blank' : ''"
                        max-width="125px"
                        max-height="125px"
                        :class="[
                          tool.disable === true
                            ? 'disableCursor rounded-xl flex d-flex flex-column justify-center'
                            : 'rounded-xl flex d-flex flex-column justify-center',
                          hover ? 'hover-card-dashboard' : '',
                        ]"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-card-text class="text-h7">
                          <i
                            :class="[tool.class, 'EoleBlue--text', hover ? [tool.animation, 'EoleYellow--text'] : '']"
                          ></i>
                          <v-badge
                            v-if="tool.id === 14 && contactNumber > 0"
                            color="EoleError"
                            :content="contactNumber"
                            offset-x="10"
                            offset-y="20"
                          ></v-badge>
                          <v-badge
                            v-if="tool.id === 19 && appointmentNumber > 0"
                            color="EoleError"
                            :content="appointmentNumber"
                            offset-x="10"
                            offset-y="20"
                          ></v-badge>
                        </v-card-text>
                        <v-card-text class="text-caption EoleBlue--text card-title">{{ tool.content }}</v-card-text>
                      </v-card>
                    </v-hover>
                  </template>

                  <span>{{ tool.content }}</span>
                </v-tooltip>
              </v-col>
            </template>
            <template v-else>
              <v-col
                v-if="(tool.admin && isAdmin) || (tool.collaborator && isCollaborator) || (tool.guest && isGuest)"
                class="text-center d-flex"
                cols="12"
                md="3"
                v-for="tool in toolsItems"
                :key="tool.id"
              >
                <v-tooltip v-model="showDescriptionTool[tool.id]" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }">
                      <v-card
                        :disabled="tool.disable === true"
                        @mouseover="hover = true"
                        @mouseout="hover = false"
                        outlined
                        elevation="3"
                        :href="tool.disable === true ? '#' : tool.path"
                        :target="tool.otherSite === true ? '_blank' : ''"
                        max-width="125px"
                        max-height="125px"
                        :class="[
                          tool.disable === true
                            ? 'disableCursor rounded-xl flex d-flex flex-column justify-center'
                            : 'rounded-xl flex d-flex flex-column justify-center',
                          hover ? 'hover-card-dashboard' : '',
                        ]"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-card-text class="text-h7">
                          <i
                            :class="[tool.class, 'EoleBlue--text', hover ? [tool.animation, 'EoleYellow--text'] : '']"
                          ></i>
                          <v-badge
                            v-if="tool.id === 14 && contactNumber > 0"
                            color="EoleError"
                            :content="contactNumber"
                            offset-x="10"
                            offset-y="20"
                          ></v-badge>
                          <v-badge
                            v-if="tool.id === 19 && appointmentNumber > 0"
                            color="EoleError"
                            :content="appointmentNumber"
                            offset-x="10"
                            offset-y="20"
                          ></v-badge>
                        </v-card-text>
                        <v-card-text class="text-caption EoleBlue--text card-title">{{ tool.content }}</v-card-text>
                      </v-card>
                    </v-hover>
                  </template>

                  <span>{{ tool.content }}</span>
                </v-tooltip>
              </v-col>
            </template>
          </v-row>
        </v-col>
      </v-row>

      <v-row no-gutters class="mt-5">
        <v-col md="5">
          <h2 v-if="verifyLength(clientItems)" class="my-4 EoleYellow--text title-text">GESTION DE MES CLIENTS</h2>
          <v-row :class="$vuetify.breakpoint.xs ? 'justify-center' : ''">
            <template v-if="$vuetify.breakpoint.xs">
              <v-col
                v-if="(client.admin && isAdmin) || (client.collaborator && isCollaborator) || (client.guest && isGuest)"
                class="text-center d-flex"
                cols="5"
                v-for="client in clientItems"
                :key="client.id"
              >
                <v-tooltip v-model="showDescriptionClient[client.id]" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }">
                      <v-card
                        :disabled="client.disable === true"
                        @mouseover="hover = true"
                        @mouseout="hover = false"
                        outlined
                        elevation="3"
                        :href="client.disable === true ? '#' : client.path"
                        :target="client.otherSite === true ? '_blank' : ''"
                        max-width="125px"
                        max-height="125px"
                        :class="[
                          client.disable === true
                            ? 'disableCursor rounded-xl justify-center'
                            : 'rounded-xl  justify-center',
                          hover ? 'hover-card-dashboard' : '',
                        ]"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-card-text class="text-h7">
                          <i
                            :class="[
                              client.class,
                              'EoleBlue--text',
                              hover ? [client.animation, 'EoleYellow--text'] : '',
                            ]"
                          ></i>
                        </v-card-text>
                        <v-card-text class="text-caption EoleBlue--text card-title">{{ client.content }}</v-card-text>
                      </v-card>
                    </v-hover>
                  </template>

                  <span>{{ client.content }}</span>
                </v-tooltip>
              </v-col>
            </template>
            <template v-else>
              <v-col
                v-if="(client.admin && isAdmin) || (client.collaborator && isCollaborator) || (client.guest && isGuest)"
                class="text-center d-flex"
                cols="12"
                md="3"
                v-for="client in clientItems"
                :key="client.id"
              >
                <v-tooltip v-model="showDescriptionClient[client.id]" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }">
                      <v-card
                        :disabled="client.disable === true"
                        @mouseover="hover = true"
                        @mouseout="hover = false"
                        outlined
                        elevation="3"
                        :href="client.disable === true ? '#' : client.path"
                        :target="client.otherSite === true ? '_blank' : ''"
                        max-width="125px"
                        max-height="125px"
                        :class="[
                          client.disable === true
                            ? 'disableCursor rounded-xl flex d-flex flex-column justify-center'
                            : 'rounded-xl flex d-flex flex-column justify-center',
                          hover ? 'hover-card-dashboard' : '',
                        ]"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-card-text class="text-h7">
                          <i
                            :class="[
                              client.class,
                              'EoleBlue--text',
                              hover ? [client.animation, 'EoleYellow--text'] : '',
                            ]"
                          ></i>
                        </v-card-text>
                        <v-card-text class="text-caption EoleBlue--text card-title">{{ client.content }}</v-card-text>
                      </v-card>
                    </v-hover>
                  </template>

                  <span>{{ client.content }}</span>
                </v-tooltip>
              </v-col>
            </template>
          </v-row>
        </v-col>
        <v-col cols="12" sm="4" md="1"> </v-col>
        <v-col md="5">
          <h2 v-if="verifyLength(extranetsItems)" class="my-4 EoleYellow--text title-text">MES EXTRANETS CLIENTS</h2>

          <v-row :class="$vuetify.breakpoint.xs ? 'justify-center' : ''">
            <template v-if="$vuetify.breakpoint.xs">
              <v-col
                v-if="
                  (extranet.admin && isAdmin) ||
                  (extranet.collaborator && isCollaborator) ||
                  (extranet.guest && isGuest)
                "
                class="text-center d-flex"
                cols="5"
                v-for="extranet in extranetsItems"
                :key="extranet.id"
              >
                <v-tooltip v-model="showDescriptionExtranet[extranet.id]" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }">
                      <v-card
                        :disabled="extranet.disable === true"
                        @mouseover="hover = true"
                        @mouseout="hover = false"
                        outlined
                        elevation="3"
                        :href="extranet.disable === true ? '#' : extranet.path"
                        :target="extranet.otherSite === true ? '_blank' : ''"
                        max-width="125px"
                        max-height="125px"
                        :class="[
                          extranet.disable === true
                            ? 'disableCursor rounded-xl flex d-flex flex-column justify-center'
                            : 'rounded-xl flex d-flex flex-column justify-center',
                          hover ? 'hover-card-dashboard' : '',
                        ]"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-card-text class="text-h7">
                          <i
                            :class="[
                              extranet.class,
                              'EoleBlue--text',
                              hover ? [extranet.animation, 'EoleYellow--text'] : '',
                            ]"
                          ></i>
                          <v-badge
                            v-if="extranet.id === 14 && contactNumber > 0"
                            color="EoleError"
                            :content="contactNumber"
                            offset-x="10"
                            offset-y="20"
                          ></v-badge>
                        </v-card-text>
                        <v-card-text class="text-caption EoleBlue--text text-truncate">{{
                          extranet.content
                        }}</v-card-text>
                      </v-card>
                    </v-hover>
                  </template>

                  <span>{{ extranet.content }}</span>
                </v-tooltip>
              </v-col>
            </template>
            <template v-else>
              <v-col
                v-if="
                  (extranet.admin && isAdmin) ||
                  (extranet.collaborator && isCollaborator) ||
                  (extranet.guest && isGuest)
                "
                class="text-center d-flex"
                cols="12"
                md="3"
                v-for="extranet in extranetsItems"
                :key="extranet.id"
              >
                <v-tooltip v-model="showDescriptionExtranet[extranet.id]" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }">
                      <v-card
                        :disabled="extranet.disable === true"
                        @mouseover="hover = true"
                        @mouseout="hover = false"
                        outlined
                        elevation="3"
                        :href="extranet.disable === true ? '#' : extranet.path"
                        :target="extranet.otherSite === true ? '_blank' : ''"
                        max-width="125px"
                        max-height="125px"
                        :class="[
                          extranet.disable === true
                            ? 'disableCursor rounded-xl flex d-flex flex-column justify-center'
                            : 'rounded-xl flex d-flex flex-column justify-center',
                          hover ? 'hover-card-dashboard' : '',
                        ]"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-card-text class="text-h7">
                          <i
                            :class="[
                              extranet.class,
                              'EoleBlue--text',
                              hover ? [extranet.animation, 'EoleYellow--text'] : '',
                            ]"
                          ></i>
                          <v-badge
                            v-if="extranet.id === 14 && contactNumber > 0"
                            color="EoleError"
                            :content="contactNumber"
                            offset-x="10"
                            offset-y="20"
                          ></v-badge>
                        </v-card-text>
                        <v-card-text class="text-caption EoleBlue--text text-truncate">{{
                          extranet.content
                        }}</v-card-text>
                      </v-card>
                    </v-hover>
                  </template>

                  <span>{{ extranet.content }}</span>
                </v-tooltip>
              </v-col>
            </template>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </section>
</template>
<script>
import config from "@/views/config/config"
import Toast from "vue-toastification"
// Import the CSS or use your own!
import "vue-toastification/dist/index.css"
const pause = ms => new Promise(resolve => setTimeout(resolve, ms))

// Import component
import Loading from "vue-loading-overlay"
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css"
import CustomeLoader from "@/layouts/components/LoaderCustom.vue"
import moment from "moment"

import emailjs from "@emailjs/browser"
import { init } from "@emailjs/browser"
init("user_gUunL4KW2ImPaGngWHYhP")

import VueApexCharts from "vue-apexcharts"

export default {
  data: () => ({
    series: [],
    chartOptions: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      xaxis: {
        // categories: [
        //   "01/01/2011 GMT",
        //   "01/02/2011 GMT",
        //   "01/03/2011 GMT",
        //   "01/04/2011 GMT",
        //   "01/05/2011 GMT",
        //   "01/06/2011 GMT",
        // ],
      },
      // legend: {
      //   position: "bottom",
      //   offsetY: 40,
      // },
      fill: {
        opacity: 1,
      },
    },

    statistics: [],
    account: {
      next_holiday: [],
    },
    emailLoading: false,
    emailInput: [],
    emailList: [], // Tableau pour stocker les adresses email
    currentInput: "",
    emails: [],
    stepperTutorial: 0,
    states: ["administrateur"],
    cityItems: [],
    cityItemsProfessionnal: [],
    searchCity: [],
    searchCityProfessionnal: [],

    agency: [],
    /* end test purpose */
    passwordValidation: null,
    lastnameErrorMessage: "",
    firstnameErrorMessage: "",
    emailErrorMessage: "",
    phoneNumberErrorMessage: "",
    validityFormPassword: true,
    password: "",
    showPassword: false,
    passwordValidationMessage: null,
    passwordErrorMessage: "",
    basicRules: [v => !!v || "Ce champ est requis"],
    phoneNumberRules: [
      v => !!v || "N° de téléphone requis",
      v => v.length === 10 || "Un numéro de téléphone Français est composé de 10 chiffres",
    ],
    passwordRules: {
      required: value => !!value || "Mot de passe requis.",
      min: v => v.length >= 12 || "Minimum 12 caractères",
    },
    emailRules: [
      v => !!v || "Une adresse électronique est requise",
      v => /.+@.+\..+/.test(v) || "L'adresse électronique doit être valide : example@gmail.com",
    ],
    stepperAccountTutorial: 0,
    editedItem: {
      id: null,
      firstname: "",
      lastname: "",
      lastConnection: "",
      lastIPConnected: "",
      statut: "utilisateur",
      email: "",
      phoneNumber: "",
      serviceChoose: "",
      idTypeContract: null,
      idTypeEmployment: null,
      idTypeJob: null,
      idTypeQualification: null,
      familyMembers: [],
      idMaritalStatus: 1,
      street: "",
      city: null,
      zip: "",
    },
    editedIndex: 0,
    dialogTutorial: false,
    complaintsCount: 0,
    customersCountVIP: 0,
    customersCount: 0,
    appointmentsAttributed: [],
    appointmentNumber: 0,
    isAdmin: false,
    isGuest: false,
    isCollaborator: false,
    grade: null,
    showDescriptionTool: [null],
    showDescriptionService: [null],
    showDescriptionClient: [null],
    showDescriptionExtranet: [null],

    waitTimeoutContact: false,
    contactNumber: 0,
    test: false,
    colors: ["indigo", "warning", "pink darken-2", "red lighten-1", "deep-purple accent-4"],
    slides: [
      {
        title: "Mes Services Assurances",
        message: "Bienvenue sur l'application << Mes Services Assurance >>",
        videoAvailable: false,
        url: require("../../../../src/assets/images/services/mesServices.png"),
      },
      {
        title: "Générez des documents plus rapidement grâce aux outils !",
        message: "Bienvenue sur l'application << Mes Services Assurance >>",
        videoAvailable: false,
        url: require("../../../../src/assets/images/services/mesServices2.png"),
      },
      {
        title: "Gérez votre cabinet de n'importe où !",
        message: "Bienvenue sur l'application << Mes Services Assurance >>",
        videoAvailable: false,
        url: require("../../../../src/assets/images/services/mesServices3.png"),
      },
    ],
    rate: false,
    rateValue: 5,
    user: {
      name: null,
      grade: null,
    },

    interlocutors: [],
    isSearch: false,
    loading: false,
    search: null,
    select: null,
    services: [],
    panel: [0],
    apiResponse: null,
    clientItems: [
      {
        id: 1,
        content: "Mes clients",
        path: "/client",
        description:
          "Vous souhaitez générer un mot de passe de manière sécurisé ou gérer vos mots de passe enregistrés ? Cliquez-ici",
        imgName: "clients",
        disable: true,
        otherSite: false,
        admin: true,
        guest: false,
        collaborator: true,
        class: "fa-solid fa-users-viewfinder fa-3x",
        animation: "fa-flip",
      },
      {
        id: 2,
        content: "Suivi sinistre",
        path: "/services/myclients",
        description:
          "Vous souhaitez générer un mot de passe de manière sécurisé ou gérer vos mots de passe enregistrés ? Cliquez-ici",
        imgName: "suivisinistre",
        disable: true,
        otherSite: true,
        admin: true,
        guest: false,
        collaborator: true,
        class: "fa-solid fa-chalkboard-user fa-3x",
        animation: "fa-flip",
      },
      {
        id: 3,
        content: "Suivi business",
        path: "/colleague",
        description:
          "Vous souhaitez consulter les fiches d'information des collaborateurs de votre cabinet ?  Cliquez-ici",
        imgName: "colleague",
        disable: true,
        otherSite: false,
        admin: true,
        guest: false,
        collaborator: false,
        class: "fa-solid fa-chart-line fa-3x",
        animation: "fa-flip",
      },
    ],
    servicesItems: [
      {
        id: 4,
        icon: "mdi-file",
        content: "Devoir de conseil",
        path: "/services/lddc",
        description:
          "Une lettre de devoir de conseil à générer pour votre client ? Pas de panique lancez-vous en cliquant ici !",
        imgName: "docs",
        disable: false,
        otherSite: false,
        admin: true,
        guest: false,
        collaborator: true,
        class: "fa-solid fa-file-contract fa-3x",
        animation: "fa-flip",
      },
      {
        id: 5,
        icon: "mdi-file",
        content: "Mandat d'étude",
        path: "/services/mandatetude",
        description: "Un mandat d'étude à générer pour votre client ? Pas de panique lancez-vous en cliquant ici !",
        imgName: "me",
        disable: false,
        otherSite: false,
        admin: true,
        guest: false,
        collaborator: true,
        class: "fa-solid fa-file-lines fa-3x",
        animation: "fa-flip",
      },
      {
        id: 6,
        icon: "mdi-file",
        content: "Mandat de résiliation",
        path: "/services/resil",
        description:
          "Un mandat de résiliation à générer pour votre client ? Pas de panique lancez-vous en cliquant ici !",
        imgName: "resiliation",
        disable: true,
        otherSite: false,
        admin: true,
        guest: false,
        collaborator: true,
        class: "fa-solid fa-file-circle-minus fa-3x",
        animation: "fa-flip",
      },
      {
        id: 7,
        icon: "mdi-file",
        content: "Signature électronique",
        path: "/services/resil",
        description:
          "Un mandat de résiliation à générer pour votre client ? Pas de panique lancez-vous en cliquant ici !",
        imgName: "resiliation",
        disable: true,
        otherSite: false,
        admin: true,
        guest: false,
        collaborator: true,
        class: "fa-solid fa-signature fa-3x",
        animation: "fa-flip",
      },
      {
        id: 8,
        content: "Découverte client",
        path: "/services/myclients",
        description:
          "Vous souhaitez générer un mot de passe de manière sécurisé ou gérer vos mots de passe enregistrés ? Cliquez-ici",
        imgName: "decouvertclient",
        disable: true,
        otherSite: false,
        admin: true,
        guest: false,
        collaborator: true,
        class: "fa-solid fa-person-circle-question fa-3x",
        animation: "fa-flip",
      },
      {
        id: 9,
        content: "Réclamations client",
        path: "/services/complaints",
        description:
          "Vous souhaitez générer un mot de passe de manière sécurisé ou gérer vos mots de passe enregistrés ? Cliquez-ici",
        imgName: "decouvertclient",
        disable: false,
        otherSite: false,
        admin: true,
        guest: false,
        collaborator: true,
        class: "fa-solid fa-triangle-exclamation fa-3x",
        animation: "fa-flip",
      },

      {
        id: 10,
        content: "Parrainage",
        path: "/services/parrain",
        description:
          "Vous souhaitez générer un mot de passe de manière sécurisé ou gérer vos mots de passe enregistrés ? Cliquez-ici",
        imgName: "decouvertclient",
        disable: false,
        otherSite: false,
        admin: false,
        guest: true,
        collaborator: false,
        class: "fa-solid fa-handshake fa-3x",
        animation: "fa-flip",
      },
      {
        id: 18,
        content: "Parrainage",
        path: "/services/sponsor",
        description:
          "Vous souhaitez consulter les fiches d'information des collaborateurs de votre cabinet ?  Cliquez-ici",
        imgName: "colleague",
        disable: false,
        otherSite: false,
        admin: true,
        guest: false,
        collaborator: true,
        class: "fa-solid fa-handshake fa-3x",
        animation: "fa-flip",
      },
      {
        id: 19,
        content: "Formulaires",
        path: "/services/forms",
        description:
          "Vous souhaitez consulter les fiches d'information des collaborateurs de votre cabinet ?  Cliquez-ici",
        imgName: "colleague",
        disable: false,
        otherSite: false,
        admin: true,
        guest: false,
        collaborator: true,
        class: "fa-solid fa-clipboard-list fa-3x",
        animation: "fa-flip",
      },
      {
        id: 20,
        content: "Mes projets",
        path: "#",
        description:
          "Vous souhaitez consulter les fiches d'information des collaborateurs de votre cabinet ?  Cliquez-ici",
        imgName: "colleague",
        disable: true,
        otherSite: false,
        admin: true,
        guest: false,
        collaborator: true,
        class: "fa-solid fa-pen fa-3x",
        animation: "fa-flip",
      },
      {
        id: 21,
        content: "Vigilance client",
        path: "/vigilance",
        description:
          "Vous souhaitez consulter les fiches d'information des collaborateurs de votre cabinet ?  Cliquez-ici",
        imgName: "colleague",
        disable: true,
        otherSite: false,
        admin: true,
        guest: false,
        collaborator: true,
        class: "fa-solid fa-circle-exclamation fa-3x",
        animation: "fa-flip",
      },
    ],

    toolsItems: [
      // {
      //   id: 1,
      //   content: "Lockself",
      //   path: "https://api.lockself.com/application/index.html#/login",
      //   description:
      //     "Vous souhaitez générer un mot de passe de manière sécurisé ou gérer vos mots de passe enregistrés ? Cliquez-ici",
      //   imgName: "lockself",
      //   disable: false,
      //   target: false,
      //   otherSite: true,
      //   admin: false,
      // },

      {
        id: 10,
        content: "Mes favoris",
        path: "/services/extranet",
        description: "Vous souhaitez accéder à vos favoris ? Cliquez-ici",
        imgName: "extranet",
        disable: false,
        target: false,
        admin: true,
        guest: false,
        collaborator: true,
        class: "fa-solid fa-heart fa-3x",
        animation: "fa-flip",
      },
      // {
      //   id: 11,
      //   content: "Suivi congé",
      //   path: "/settings/holiday",
      //   description: "Vous souhaitez accéder à votre espace de gestion des congés ? Cliquez-ici",
      //   imgName: "hollidays",
      //   disable: false,
      //   target: false,
      //   otherSite: true,
      //   admin: true,
      //   guest: false,
      //   collaborator: true,
      //   class: "fa-solid fa-plane-departure fa-3x",
      // },
      {
        id: 12,
        content: "Classeur ACPR",
        path: "/",
        description: "Vous souhaiter extraire les données pour la génération de votre classeur ACPR ? Cliquez-ici",
        imgName: "acpr",
        disable: true,
        target: false,
        otherSite: false,
        admin: true,
        guest: false,
        collaborator: true,
        class: "fa-solid fa-person-military-pointing fa-3x",
        animation: "fa-flip",
      },
      {
        id: 13,
        content: "Mes charges",
        path: "/services/charges",
        description:
          "Vous souhaitez générer un mot de passe de manière sécurisé ou gérer vos mots de passe enregistrés ? Cliquez-ici",
        imgName: "services",
        disable: false,
        otherSite: false,
        admin: true,
        guest: false,
        collaborator: false,
        class: "fa-solid fa-wallet fa-3x",
        animation: "fa-flip",
      },
      // {
      //   id: 21,
      //   content: "Mes commissions",
      //   path: "/services/commissions",
      //   description:
      //     "Vous souhaitez générer un mot de passe de manière sécurisé ou gérer vos mots de passe enregistrés ? Cliquez-ici",
      //   imgName: "services",
      //   disable: false,
      //   otherSite: false,
      //   admin: true,
      //   guest: false,
      //   collaborator: false, class: "fa-solid fa-piggy-bank fa-3x",
      //   animation: "fa-flip",
      // },
      {
        id: 14,
        content: "Rappel de client",
        path: "/contact",
        description: "Vous souhaiter affecter une demande de rappel à l'un des membres de votre équipe ? \nCliquez-ici",
        imgName: "services",
        disable: false,
        otherSite: false,
        admin: true,
        guest: false,
        collaborator: true,
        class: "fa-solid fa-phone-volume fa-3x",
        animation: "fa-shake",
      },
      {
        id: 15,
        content: "Mes collègues",
        path: "/colleague",
        description:
          "Vous souhaitez consulter les fiches d'information des collaborateurs de votre cabinet ?  Cliquez-ici",
        imgName: "colleague",
        disable: false,
        otherSite: false,
        admin: true,
        guest: false,
        collaborator: true,
        class: "fa-solid fa-people-group fa-3x",
        animation: "fa-flip",
      },
      {
        id: 16,
        content: "Mes contact compagnie",
        path: "/services/partners",
        description:
          "Vous souhaitez consulter les fiches d'information des collaborateurs de votre cabinet ?  Cliquez-ici",
        imgName: "colleague",
        disable: false,
        otherSite: false,
        admin: true,
        guest: false,
        collaborator: true,
        class: "fa-solid fa-user-tie fa-3x",
        animation: "fa-flip",
      },
      {
        id: 17,
        content: "Ma compta",
        path: "/colleague",
        description:
          "Vous souhaitez consulter les fiches d'information des collaborateurs de votre cabinet ?  Cliquez-ici",
        imgName: "colleague",
        disable: true,
        otherSite: false,
        admin: true,
        guest: false,
        collaborator: false,
        class: "fa-solid fa-euro-sign fa-3x",
        animation: "fa-flip",
      },
      {
        id: 19,
        content: "Rendez-vous",
        path: "/services/appointment",
        description:
          "Vous souhaitez consulter les fiches d'information des collaborateurs de votre cabinet ?  Cliquez-ici",
        imgName: "colleague",
        disable: false,
        otherSite: false,
        admin: true,
        guest: false,
        collaborator: true,
        class: "fa-solid fa-person-walking-luggage fa-3x",
        animation: "fa-flip",
      },
      {
        id: 20,
        content: "Mes produits",
        path: "/services/products",
        description:
          "Vous souhaitez consulter les fiches d'information des collaborateurs de votre cabinet ?  Cliquez-ici",
        imgName: "colleague",
        disable: false,
        otherSite: false,
        admin: true,
        guest: false,
        collaborator: true,
        class: "fa-solid fa-binoculars fa-3x",
        animation: "fa-flip",
      },
      {
        id: 21,
        content: "Mon espace entreprise",
        path: "/services/table",
        description:
          "Vous souhaitez consulter les fiches d'information des collaborateurs de votre cabinet ?  Cliquez-ici",
        imgName: "colleague",
        disable: false,
        otherSite: false,
        admin: true,
        guest: false,
        collaborator: true,
        // class: "fa-solid fa-comment-dots fa-3x",
        class: "fa-solid fa-house-chimney-user fa-3x",

        animation: "fa-flip",
      },
    ],
    extranetsItems: [
      {
        id: 18,
        icon: "mdi-car-info",
        content: "Déclarer un sinistre",
        path: "/services/sinister",
        description: "Vous souhaitez déclarer un sinistre sur votre véhicule personnel ou professionnel ? Cliquez-ici",
        imgName: "sinistre",
        disable: true,
        otherSite: false,
        admin: true,
        guest: false,
        collaborator: true,
        class: "fa-solid fa-car-burst fa-3x",
        animation: "fa-flip",
      },
      {
        id: 19,
        icon: "mdi-link-variant",
        content: "Flotte auto",
        path: "",
        description:
          "Vous souhaitez gérer votre flotte automobile et garder un oeil sur vos contrats en cours ? Cliquez-ici",
        imgName: "flotteauto",
        disable: true,
        otherSite: false,
        admin: true,
        guest: false,
        collaborator: true,
        class: "fa-solid fa-truck-field fa-3x",
        animation: "fa-flip",
      },

      {
        id: 20,
        icon: "mdi-link-variant",
        content: "Bulletin social individuel",
        path: "/services/bsi",
        description:
          "Vous souhaitez générer ou gérer un bulletin social individuel pour votre entreprise ? Cliquez-ici",
        imgName: "bsi",
        disable: true,
        otherSite: false,
        admin: true,
        guest: false,
        collaborator: true,
        class: "fa-solid fa-file-contract fa-3x",
        animation: "fa-flip",
      },
    ],
    items: [],
    drawer: true,
    breadcrumbsItems: [
      {
        text: "Accueil",
        disabled: false,
        href: "/",
      },
    ],
    searchEtablishment: null,
    typeEmployments: [],
    typeContracts: [],
    typeJobs: [],
    typeQualifications: [],
  }),
  computed: {
    familyOptions() {
      if (
        this.editedItem.idMaritalStatus === 3 ||
        this.editedItem.idMaritalStatus === 2 ||
        this.editedItem.idMaritalStatus === 4
      ) {
        return [
          {
            id: 1,
            designation: "Conjoint(e)",
          },
          {
            id: 2,
            designation: "Enfant",
          },
        ]
      } else {
        return [
          {
            id: 2,
            designation: "Enfant",
          },
        ]
      }
    },
  },
  watch: {
    // emailInput: {
    //   handler(newValue) {
    //     console.log(newValue)

    //     const emailList = [] // Créez une nouvelle liste pour stocker les adresses e-mail.

    //     newValue.forEach(element => {
    //       const matches = element.match(/<([^>]+)>/g) // Utilisez /g pour correspondre à toutes les occurrences dans la chaîne.
    //       if (matches) {
    //         matches.forEach(match => {
    //           const email = match.slice(1, -1) // Supprimez les chevrons autour de l'e-mail.
    //           emailList.push(email)
    //         })
    //       } else {
    //         emailList.push(element)
    //       }
    //     })

    //     this.emailList = emailList
    //   },
    // },
    search(val) {
      val && val !== this.select && this.querySelections(val)
    },
    rateValue() {
      this.$toast.info(`Merci pour votre note de ${this.rateValue}/5 ✭`, {
        position: "bottom-right",
        timeout: 3000,
      })
      this.rate = false
    },
    searchCityProfessionnal(val) {
      //verify if is number
      if (/^\d{5}$/.test(val)) {
        try {
          fetch("https://geo.api.gouv.fr/communes?codePostal=" + val)
            .then(res => res.clone().json())
            .then(res => {
              this.cityItemsProfessionnal = res.map(item => item.nom)
              this.editedItem.mainWorkplaceZip = val
            })
            .catch(err => {
              console.log(err)
            })

          this.successSelectCity = true
          this.successSelectCityMessage = `Nous avons trouvé ${data.length} villes ou communes ayant comme code postal ${val}`
        } catch (e) {
          this.errorSelectCity = true
          this.errorSelectCityMessage = "Une erreur est survenue"
        }
      } else {
        this.errorSelectCity = true
        this.errorSelectCityMessage = "Le code postal doit être composé de 5 chiffres"
      }
    },
    searchCity(val) {
      //verify if is number
      if (/^\d{5}$/.test(val)) {
        try {
          fetch("https://geo.api.gouv.fr/communes?codePostal=" + val)
            .then(res => res.clone().json())
            .then(res => {
              this.cityItems = res.map(item => item.nom)
              this.editedItem.zip = val
            })
            .catch(err => {
              console.log(err)
            })

          this.successSelectCity = true
          this.successSelectCityMessage = `Nous avons trouvé ${data.length} villes ou communes ayant comme code postal ${val}`
        } catch (e) {
          this.errorSelectCity = true
          this.errorSelectCityMessage = "Une erreur est survenue"
        }
      } else {
        this.errorSelectCity = true
        this.errorSelectCityMessage = "Le code postal doit être composé de 5 chiffres"
      }
    },
    searchEtablishment() {
      this.editedItem.mainWorkplaceStreet = this.agency.etablishment.find(
        element => element.id === this.searchEtablishment,
      ).street
      this.editedItem.mainWorkplaceCity = this.agency.etablishment.find(
        element => element.id === this.searchEtablishment,
      ).city
      this.searchCityProfessionnal = this.agency.etablishment.find(
        element => element.id === this.searchEtablishment,
      ).zip
    },
  },
  components: { Loading, CustomeLoader, apexchart: VueApexCharts },
  created() {
    localStorage.getItem("tutorial") == 1 ? (this.dialogTutorial = true) : (this.dialogTutorial = false)

    this.grade = localStorage.getItem("grade")
    this.grade === "administrateur" ? (this.isAdmin = true) : (this.isAdmin = false)
    this.grade === "collaborateur" ? (this.isCollaborator = true) : (this.isCollaborator = false)
    this.grade === "guest" ? (this.isGuest = true) : (this.isGuest = false)

    this.isCollaborator || this.isAdmin ? this.getContactNumber() : null
    this.isCollaborator || this.isAdmin ? this.getAppointmentNumber() : null

    this.servicesItems.forEach(element => {
      this.items.push(element.content)
    })
    this.toolsItems.forEach(element => {
      this.items.push(element.content)
    })

    this.user.name = localStorage.getItem("complete_name")
    this.user.grade = localStorage.getItem("grade")
    this.user.agency = localStorage.getItem("agency")

    this.fetchAttributedAppointments(localStorage.getItem("user_id"))
    this.fetchAgencyCounter()
    this.fetchAccountCounter()

    this.setEditedItem()
    this.fetchAgency()
    this.fetchType()
  },
  methods: {
    testEmail() {
      const emailList = [] // Créez une nouvelle liste pour stocker les adresses e-mail.

      this.emailInput.forEach(element => {
        const matches = element.match(/<([^>]+)>/g) // Utilisez /g pour correspondre à toutes les occurrences dans la chaîne.
        if (matches) {
          matches.forEach(match => {
            const email = match.slice(1, -1) // Supprimez les chevrons autour de l'e-mail.
            emailList.push(email)
          })
        } else {
          emailList.push(element)
        }
      })

      this.emailList = emailList
      this.emailInput = this.emailList
    },
    async putAccountById() {
      const data = this.editedItem
      const formData = new FormData()
      formData.append("place", "putAccount")
      formData.append("id", data.id)
      formData.append("lastname", data.lastname)
      formData.append("firstname", data.firstname)
      formData.append("idState", data.grade)
      formData.append("email", data.email)
      formData.append("phoneNumber", data.phoneNumberProfessional)
      formData.append("idServiceManager", data.idServiceManager)
      formData.append("idTypeContract", data.idTypeContract)
      formData.append("idTypeJob", data.idTypeJob)
      formData.append("idTypeEmployment", data.idTypeEmployment)
      formData.append("idTypeQualification", data.idTypeQualification)
      formData.append("entryDate", data.entryDate)
      formData.append("idService", data.idService)
      formData.append("street", data.street)
      formData.append("city", data.city)
      formData.append("zip", data.zip)
      formData.append("idMaritalStatus", data.idMaritalStatus)
      formData.append("mainWorkplaceZip", data.mainWorkplaceZip)
      formData.append("mainWorkplaceCity", data.mainWorkplaceCity)
      formData.append("mainWorkplaceStreet", data.mainWorkplaceStreet)
      formData.append("personnalPhoneNumber", data.personnalPhoneNumber)
      formData.append("personnalEmail", data.personnalEmail)
      formData.append("idCollectiveAgreement", data.idCollectiveAgreement)

      data.familyMembers.length > 0 ? formData.append("familyMembers", JSON.stringify(data.familyMembers)) : null

      try {
        const response = await fetch(`${config.apiUri}/php/pages/accounts.php`, {
          mode: "cors",
          method: "POST",
          body: formData,
        })
        this.$toast.success(
          `Vous venez de modifier le compte ${data.lastname.toUpperCase()} ${data.firstname.toUpperCase()} avec succès.`,
          {
            position: "bottom-right",
            timeout: 3000,
          },
        )

        this.stepperAccountTutorial++
      } catch (e) {
        console.log(e)
        this.$toast.error(`Une erreur est survenue.\nImpossible de modifier le compte`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    async sendInvitationMail() {
      async function postGuestAccount(email) {
        try {
          const formData = new FormData()
          formData.append("place", "addGuestAccount")
          formData.append("idMasterAccount", localStorage.getItem("master_account_linked"))
          formData.append("idAgency", localStorage.getItem("agency_id"))
          formData.append("email", email)

          const response = await fetch(`${config.apiUri}/php/pages/accounts.php`, {
            mode: "cors",
            method: "POST",
            body: formData,
          })

          let data = await response.json()

          if (data.code === 3) {
            return { idAccount: data.id_account, token: data.token }
          } else {
            throw new Error("Une erreur est survenue")
          }
        } catch (e) {
          console.log(e)
        }
      }

      // Utilisez une fonction itérative asynchrone pour introduire un délai entre chaque envoi d'e-mail
      async function sendEmailWithDelay(email) {
        try {
          let { idAccount, token } = await postGuestAccount(email)

          await emailjs.send("service_5p49t7p", "template_hkv4wtz", {
            to_email: email,
            link: `${config.appUri}/auth/login?firstconnection=1&id=${idAccount}&token=${token}`,
          })
        } catch (error) {
          console.log(error)
        }
      }

      for (const collaborator of this.emailList) {
        // Introduisez un délai de 4 secondes (4000 millisecondes) entre chaque envoi d'e-mail
        this.emailLoading = true
        await new Promise(resolve => setTimeout(resolve, 1000))

        await sendEmailWithDelay(collaborator).then(() => {
          this.emailLoading = false
        })
      }
    },
    // handleEmailInput() {
    //   // Assurez-vous que emailInput est une chaîne de caractères
    //   let inputString = Array.isArray(this.emailInput) ? this.emailInput.join("; ") : this.emailInput

    //   // Expression régulière pour extraire les adresses email
    //   const emailRegex = /[\w.-]+@[\w.-]+\.\w+/g
    //   const matches = inputString.match(emailRegex)

    //   if (matches) {
    //     // Mettre à jour le tableau emailList avec les adresses email trouvées
    //     this.emailList = matches
    //   }
    // },

    extractEmail(text) {
      const emailPattern = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g
      return text.split(";").filter(email => emailPattern.test(email.trim()))
    },

    addEmail(email) {
      console.log(this.validateEmail(email))
      if (this.validateEmail(email) && !this.emails.includes(email)) {
        this.emails.push(email)
      }
    },
    validateEmail(email) {
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
    async fetchType() {
      let headers = new Headers()

      headers.append("Content-Type", "application/json")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")

      await pause(1500)

      try {
        let response = await fetch(`${config.apiUri}/type_contract`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })

        const data = await response.json()

        if (data.code === 1) {
          data.type_employments.forEach(employment => {
            this.typeEmployments.push(employment)
          })
          data.type_contracts.forEach(contract => {
            this.typeContracts.push(contract)
          })
          data.type_jobs.forEach(job => {
            this.typeJobs.push(job)
          })
          data.type_qualifications.forEach(qualification => {
            this.typeQualifications.push(qualification)
          })
        }
      } catch (e) {
        console.log(e)
        this.$toast.error("Impossible d'accéder aux données lié aux comptes.\nMerci de réessayer ultérieurement.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    addLine(array) {
      array.push({
        maritalStatus: null,
        firstname: null,
        lastname: null,
        createClient: true,
      })

      this.$nextTick(() => {
        const inputElement = document.getElementById("input-family-" + (array.length - 1))
        if (inputElement) {
          inputElement.focus()
        }
      })
    },
    async fetchAccountCounter() {
      this.account = []

      let headers = new Headers()
      let idAccount = localStorage.getItem("user_id")

      headers.append("Content-Type", "application/json")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")

      try {
        let response = await fetch(`${config.apiUri}/accounts/${idAccount}/counter`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        if (data.code === 1) {
          this.account = data.account[0]

          this.statistics = data.account[0].weekly_requests

          // Format next holiday dates using moment.js
          if (this.account.next_holiday && this.account.next_holiday.length > 0) {
            this.account.next_holiday = this.account.next_holiday.map(holiday => ({
              start: moment(holiday.start_date).format("DD/MM/YYYY"),
              end: moment(holiday.end_date).format("DD/MM/YYYY"),
            }))
          }
        }
        this.updateChartData(this.statistics)
      } catch (e) {
        console.log(e)
      }
    },
    async updateChartData(weeklyRequests) {
      // Préparer les données pour les séries du graphique
      const dataDemandesEnCours = weeklyRequests.map(item => ({
        x: moment(item.request_date).format("Do MMM"), // La date de la demande
        y: item.request_count_contact, // Le nombre de demandes en cours pour cette date
      }))

      const dataDemandesTraitees = weeklyRequests.map(item => ({
        x: moment(item.request_date).format("Do MMM"), // La date de la demande
        y: item.request_count_contact_history, // Le nombre de demandes traitées pour cette date
      }))

      // Mettre à jour les séries de données pour le graphique
      this.series = [
        {
          name: "Demandes en cours",
          data: dataDemandesEnCours,
          color: "#FF4560", // Rouge, par exemple
        },
        {
          name: "Demandes traitées",
          data: dataDemandesTraitees,
          color: "#00E396", // Vert, par exemple
        },
      ]
    },
    async fetchAgency() {
      this.agency = []

      let headers = new Headers()
      let agencyId = localStorage.getItem("agency_id")

      headers.append("Content-Type", "application/json")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")

      try {
        let response = await fetch(`${config.apiUri}/agencies/${agencyId}`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        if (data.code === 1) {
          this.agency = data.agency[0]
        }
      } catch (e) {
        console.log(e)
      }
    },
    save(idUser) {
      // this.put(idUser, {
      //   lastname: this.editedItem.lastname,
      //   firstname: this.editedItem.firstname,
      //   grade: this.editedItem.statut,
      //   email: this.editedItem.email,
      //   phoneNumber: this.editedItem.phoneNumber,
      //   idServiceManager: this.editedItem.serviceChoose,
      //   idTypeContract: this.editedItem.idTypeContract,
      //   idTypeJob: this.editedItem.idTypeJob,
      //   idTypeEmployment: this.editedItem.idTypeEmployment,
      //   idTypeQualification: this.editedItem.idTypeQualification,
      //   familyMembers: this.editedItem.familyMembers,
      //   entryDate: this.editedItem.entryDate,
      //   idService: this.editedItem.idService,
      //   street: this.editedItem.street,
      //   city: this.editedItem.city,
      //   zip: this.editedItem.zip,
      //   idMaritalStatus: this.editedItem.idMaritalStatus,
      // })
      this.stepperAccountTutorial++
      this.stepperTutorial++
    },

    async putAccount(idUser, data) {
      const formData = new FormData()
      formData.append("place", "putAccount")
      formData.append("id", idUser)
      formData.append("lastname", data.lastname)
      formData.append("firstname", data.firstname)
      formData.append("idState", data.idState)
      formData.append("email", data.email)
      formData.append("phoneNumber", data.phoneNumber)
      formData.append("idServiceManager", data.idServiceManager)
      formData.append("idTypeContract", data.idTypeContract)
      formData.append("idTypeJob", data.idTypeJob)
      formData.append("idTypeEmployment", data.idTypeEmployment)
      formData.append("idTypeQualification", data.idTypeQualification)
      formData.append("entryDate", data.entryDate)
      formData.append("idService", data.idService)
      formData.append("street", data.street)
      formData.append("city", data.city)
      formData.append("zip", data.zip)
      formData.append("idMaritalStatus", data.idMaritalStatus)

      data.familyMembers.length > 0 ? formData.append("familyMembers", JSON.stringify(data.familyMembers)) : null

      try {
        const response = await fetch(`${config.apiUri}/php/pages/accounts.php`, {
          mode: "cors",
          method: "POST",
          body: formData,
        })
        this.$toast.info(
          `Vous venez de modifier le compte ${data.lastname.toUpperCase()} ${data.firstname.toUpperCase()} avec succès.`,
          {
            position: "bottom-right",
            timeout: 3000,
          },
        )
        this.stepperAccountTutorial = 0
      } catch (e) {
        console.log(e)
        this.$toast.error(`Une erreur est survenue.\nImpossible de modifier le compte`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    setEditedItem() {
      this.editedItem = {
        id: localStorage.getItem("user_id"),
        firstname: localStorage.getItem("firstname"),
        lastname: localStorage.getItem("lastname"),
        lastConnection: "",
        lastIPConnected: "",
        statut: localStorage.getItem("grade"),
        email: localStorage.getItem("email"),
        phoneNumberProfessional: localStorage.getItem("phone"),
        idTypeContract: null,
        idTypeEmployment: null,
        idTypeJob: null,
        idTypeQualification: null,
        familyMembers: [],
        idMaritalStatus: 1,
      }
    },
    async fetchAgencyCounter() {
      let headers = new Headers()
      const idAgency = localStorage.getItem("agency_id")

      headers.append("Content-Type", "application/json")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")

      const response = await fetch(`${config.apiUri}/agencies/${idAgency}/counter`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      })

      const data = await response.json()

      this.customersCount = data.count_professionnal + data.count_particular
      this.customersCountVIP = data.count_customer_professionnal_vip
      this.complaintsCount = data.count_complaints
    },
    appointmentRoute() {
      this.$router.push("/services/appointment?vtab=tab-2")
    },
    async fetchAttributedAppointments(idCollaborator) {
      let now = moment().format("YYYY-MM-DD")
      let anteriatory = false
      let time = 0
      this.appointmentsAttributed = []
      this.waitTimeoutAppointmentsAttributed = true

      let headers = new Headers()
      headers.append("Content-Type", "application/json charset=UTF-8")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")

      await pause(1500)

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")

        const response = await fetch(`${config.apiUri}/accounts/${idCollaborator}/appointment`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })

        const data = await response.json()

        data.forEach(el => {
          anteriatory = false
          moment(now).isAfter(el.date_start) ? (anteriatory = true) : (anteriatory = false)

          anteriatory
            ? (time = moment(now).diff(moment(el.date_start).format("YYYY-MM-DD"), "days"))
            : (time = moment(el.date_start).diff(moment(now).format("YYYY-MM-DD"), "days"))

          this.appointmentsAttributed.push({
            id: el.id,
            date: moment(el.date_start).format("dddd Do MMMM YYYY"),
            hourStart: moment(el.date_start).format("HH:mm"),
            hourEnd: moment(el.date_end).format("HH:mm"),
            lastname: el.lastname,
            firstname: el.firstname,
            appointment_type: el.appointment_type,
            agency: el.agency,
            contracts: el.contracts,
            anteriatory: anteriatory,
            time: time,
            date_start: el.date_start,
          })
        })
      } catch (e) {
        console.log(e)
      }
    },

    verifyLength(module) {
      const grade = localStorage.getItem("grade")
      // verify if one item in array have a parameter guest or collaborator or admin in true if yes return true
      let result = false
      switch (grade) {
        case "administrateur":
          result = module.some(item => item.admin === true)
          return result
        case "collaborateur":
          result = module.some(item => item.collaborator === true)
          return result
        case "guest":
          result = module.some(item => item.guest === true)
          return result
      }
      return result
    },
    async getContactNumber() {
      this.waitTimeoutContact = true

      await pause(1500)

      try {
        let headers = new Headers()
        let userId = localStorage.getItem("user_id")

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(`${config.apiUri}/php/pages/client_contact.php?idAccount=${userId}&count=true`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })

        const data = await response.json()
        this.contactNumber = data.contact_number

        this.waitTimeoutContact = false
      } catch (e) {
        console.log(e)
      }
    },

    async getAppointmentNumber() {
      this.waitTimeoutContact = true

      await pause(1500)

      try {
        let headers = new Headers()
        const idAgency = localStorage.getItem("agency_id")

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(`${config.apiUri}/agencies/${idAgency}/appointment/number`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })

        const data = await response.json()
        this.appointmentNumber = data.appointment_number

        this.waitTimeoutContact = false
      } catch (e) {
        console.log(e)
      }
    },
    download() {
      this.$toast.error(
        "L'application Mes Services Assurance n'est pas encore disponible sous forme d'application.\nNous vous tiendrons informé dès lors qu'elle sera déployée",
        { position: "bottom-right", timeout: 3000 },
      )
    },
    querySelections(v) {
      this.loading = true
      this.isSearch = true
      // Simulated ajax query
      setTimeout(() => {
        this.items = this.states.filter(e => {
          return (e || "").toLowerCase().indexOf((v || "").toLowerCase()) > -1
        })
        this.loading = false
      }, 500)
    },
  },
  mounted() {},
  beforeDestroy() {},
}
</script>
<style lang="scss" scoped>
.hover-card-dashboard:hover {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  transform: scale(1.08);
}

// if hover is leave

.test-responsive {
  width: 100% !important;
}

.mullerFont {
  font-family: muller;
}

.title-text {
  font-family: muller;

  font-size: 1.25rem;
}

.v-card--reveal {
  background-color: #001f47;
  color: white;
  opacity: 0.9;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 999;
  width: 100%;
  border-radius: 20px;
}

.v-card--reveal-true {
  background-color: #001f47;
  color: white;
  opacity: 1;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 999;
  width: 100%;
  border-radius: 20px;
}

.services-font {
  font-size: 10px;
}

.services {
  //background-color: #001f47;
  background-image: url("../../../../src/assets/images/services/bgg.png");
  background-size: cover;
  padding: 5.5rem;
  margin-bottom: 20px;
}

.test {
  //background-color: #001f47;
  background-image: url("../../../../src/assets/images/services/mesServices.png");
  background-size: cover;
  padding: 3rem;
  color: white !important;

  .text-h4 {
    color: white !important;
    font-weight: 800;
  }
}

.carousel {
  color: white !important;

  .text-h4 {
    color: white !important;
    font-weight: 800;
  }
}

.title {
  font-size: 10rem !important;
}

@media (max-width: 600px) {
  .services {
    padding: 1.5rem !important;
  }
}

.greeting-card {
  position: relative;

  .greeting-card-bg {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .greeting-card-trophy {
    position: absolute;
    bottom: 10%;
    right: 8%;
  }
}

// rtl
.v-application {
  &.v-application--is-rtl {
    .greeting-card-bg {
      right: initial;
      left: 0;
      transform: rotateY(180deg);
    }

    .greeting-card-trophy {
      left: 8%;
      right: initial;
    }
  }

  .disableCursor:hover {
    cursor: not-allowed;
  }
}

.card-title {
  line-height: 0.8rem !important;
  letter-spacing: 0 !important;
}
</style>
